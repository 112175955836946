@import url("https://use.typekit.net/uir2rox.css");


.filsonLight {
	font-family: filson-pro, sans-serif;
    font-weight: 200;
}
.filsonBook {
    font-family: filson-pro, sans-serif;
    font-weight: 300;
}
.filsonRegular {
    font-family: filson-pro, sans-serif;
    font-weight: 400;
}
.filsonMedium {
    font-family: filson-pro, sans-serif;
    font-weight: 500;
}
.filsonBold {
    font-family: filson-pro, sans-serif;
    font-weight: 700;
}
.filsonHeavy {
    font-family: filson-pro, sans-serif;
    font-weight: 800;
}
.filsonBlack {
    font-family: filson-pro, sans-serif;
    font-weight: 900;
}